import { useState } from "react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import "@xyflow/react/dist/style.css";

import { createSearchLeadsStore, TabContext } from "../PeopleSearch/store";

import HelpSteps from "./HelpSteps";

import Icons from "../Icons";
import WorkFlow from "./WorkFlow";
import WalkThrough from "./WalkThrough";

const AutoPilotLeadsWorkflow: React.FC = () => {
  const [activeView, setActiveView] = useState("walkthrough");

  const navigate = useNavigate();

  const [needHelp, setNeedHelp] = useState(false);

  const workflowViewOptions = [
    {
      id: "walkthrough",
      label: "Walkthrough",
    },
    {
      id: "workflow",
      label: "Workflow",
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between bg-white px-[32px] py-[8px]">
        <p className="text-[24px] font-bold leading-[32px]">
          Autopilot - Leads Workflow
        </p>
        <ButtonGroup>
          <Button
            variant={"outline"}
            size={"sm"}
            onClick={() => setNeedHelp(true)}
          >
            Need help ?
          </Button>

          <Button
            size={"sm"}
            colorScheme="primary"
            variant="outline"
            leftIcon={<Icons.BackIcon />}
            className="h-[40px] rounded-md !border-none !bg-light !text-textPurple"
            onClick={() => {
              navigate({ to: "/auto-pilot" });
            }}
          >
            Go Back
          </Button>
        </ButtonGroup>
      </div>
      <div className="flex items-center justify-center">
        {workflowViewOptions.map((option) => (
          <Button
            key={option.id}
            variant={"outline"}
            size={"sm"}
            onClick={() => setActiveView(option.id)}
            className={
              activeView === option.id
                ? " !bg-light !text-textPurple !border-light"
                : ""
            }
          >
            {option.label}
          </Button>
        ))}
      </div>

      <TabContext.Provider value={createSearchLeadsStore("autopilot_1")}>
        {activeView === "walkthrough" ? (
          <WalkThrough />
        ) : (
          <>
            <WorkFlow />
            <HelpSteps needHelp={needHelp} setNeedHelp={setNeedHelp} />
          </>
        )}
      </TabContext.Provider>
    </>
  );
};

export default AutoPilotLeadsWorkflow;
