import {
  Button,
  ButtonGroup,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { countries } from "countries-list";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import AccordionButton from "../Common/AccordionButton";
import MultiSelectDropdown from "../Common/MultiSelectDropdown";
import PersanaInput from "../Common/PersanaInput";
import SearchInputAccordion from "../Common/SearchInputAccordion";

import CompanyIcon from "@/assets/svgs/people-search-company.svg";
import JobTitleIcon from "@/assets/svgs/people-search-job-title.svg";
import LocationIcon from "@/assets/svgs/people-search-location.svg";
import NameIcon from "@/assets/svgs/people-search-name.svg";
import {
  InputtedData,
  useSearchLeadsStore,
} from "@/components/PeopleSearch/store.ts";
import leadService from "@/services/leads.service";

import { SearchCompanyType } from "@/types/leads.types";
import {
  convertSearchPeoplePayload,
  extractNameAndContinent,
  filterValidObjKeys,
  generateId,
  handlePushToArray,
} from "@/utils";
import { apolloCompanySize, jobTitles } from "@/utils/constants";
import { usePeopleSearch } from "./hooks/usePeopleSearch";
import Icons from "../Icons";
import { SaveCurrentFilter } from "./components/SaveFilters";
import { ControlledTabs } from "../Common/TabController";
import SavedSearchesUI from "./components/SavedFilters";
import { calculateAppliedFiltersCount } from "./utils";
import { userStore } from "@/stores/user.store";
import Countries from "../Leads/FindCompanies/Countries";
import CompanyNameSearches from "../Common/Searches/CompanyNameSearch";
import CompanySidebarComponent from "../CompanySearch/Sidebar";
import Educations from "./components/Educations";
import RadioButton from "../Common/RadioButton";
import Seniorities from "./components/Seniorities";

const SidebarComponent = ({
  isForAutoPilot = false,
}: {
  isForAutoPilot?: boolean;
}) => {
  const toast = useToast();
  let timer: NodeJS.Timeout;
  const [fetchedCompanies, setFetchedCompanies] = useState<any[]>([]);
  const saveFilterModalDisclosure = useDisclosure();
  const inputtedData = useSearchLeadsStore((state) => state.sideBarInput);
  const isUseMixRank = userStore((state) => state.isUseMixrank);
  const updateUserState = userStore((state) => state.updateUserState);
  const valueLabels = useSearchLeadsStore((state) => state.valueLabels);
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);
  const setInputtedData = (data: InputtedData) => {
    updateLeadState({ sideBarInput: data });
  };
  const isSearching = useSearchLeadsStore((state) => state.isSearching);
  const totalFilters = useMemo(
    () => calculateAppliedFiltersCount(inputtedData),
    [inputtedData],
  );
  const { mutateAsync } = useMutation({
    mutationFn: ({ payload }: { payload: SearchCompanyType }) =>
      leadService.searchCompany(payload),
  });
  const currentUser = userStore((state) => state.currentUser);

  const { mutateAsync: searchPeopleMutateAsync } = usePeopleSearch();
  const _peopleSearchPayload = useSearchLeadsStore(
    (state) => state.peopleSearchPayload,
  );
  const applyFilterData = useSearchLeadsStore((state) => state.applyFilterData);
  const id = useSearchLeadsStore((state) => state.instanceId);
  const genId = (field: string) => `${id}-${field}`;

  const handleCompanySearch = async (companyName: string) => {
    // Clear previous timer if it exists
    clearTimeout(timer);

    // Set a new timer to call the API after 0.5s of user inactivity
    timer = setTimeout(async () => {
      await mutateAsync(
        {
          payload: {
            q_organization_name: companyName,
            per_page: 50,
          },
        },
        {
          onSuccess: (response) => {
            // Update the state
            setFetchedCompanies(response?.data.companies || []);
            updateLeadState({
              valueLabels: {
                ...valueLabels,
                ...Object.fromEntries(
                  (response?.data.companies || []).map((company: any) => [
                    company?.organization_id ??
                      company?.id ??
                      company?.owned_by_organization_id,
                    company?.name,
                  ]),
                ),
              },
            });
          },
          onError: (error) => {
            console.log(error);
          },
        },
      );
    }, 500);
  };

  const handleSearch = useCallback(
    async (inputtedData: InputtedData) => {
      const convertedInputtedData = convertSearchPeoplePayload(inputtedData);
      const validInputtedData = filterValidObjKeys(convertedInputtedData);

      if (Object.keys(validInputtedData || {}).length === 0) {
        toast({
          title: "No data to search",
          description: "Please input some data to search",
          status: "error",
          isClosable: true,
          duration: 2000,
          position: "top-right",
        });
        return;
      }

      console.log("fetchedCompanies", fetchedCompanies);
      updateLeadState({
        isSearching: true,
        valueLabels: {
          ...valueLabels,
          ...Object.fromEntries(
            fetchedCompanies.map((company: any) => [
              company?.organization_id ??
                company.owned_by_organization_id ??
                (company?.id || company?.value),
              company.name || company?.company_name || company?.label,
            ]),
          ),
        },
      });
      await searchPeopleMutateAsync({
        payload: validInputtedData,
        isUseMixrank: isUseMixRank,
      });
    },
    [
      searchPeopleMutateAsync,
      updateLeadState,
      toast,
      fetchedCompanies,
      valueLabels,
      isUseMixRank,
    ],
  );
  useEffect(() => {
    if (!applyFilterData) return;
    handleSearch(inputtedData);
    updateLeadState({ applyFilterData: false });
  }, [applyFilterData, inputtedData, handleSearch]);

  useEffect(() => {
    const peopleSearchPayload =
      window.peopleSearchPayload ?? _peopleSearchPayload;
    if (!peopleSearchPayload) return;
    try {
      let newInputData = {
        search_text: "",
        person_titles: [],
        person_not_titles: [],
        person_past_titles: [],
        person_seniorities: [],
        q_person_name: "",
        organization_ids: [], // array of company ids
        not_organization_ids: [], // array of company ids
        person_past_organization_ids: [], // array of company ids
        organization_industry_tag_ids: [], // array of industry ids
        organization_num_employees_ranges: [], // array of employee range ["20001,5000"],
        q_organization_keyword_tags: [], // array of company tags
        q_not_organization_keyword_tags: [], // array of company tags
        person_locations: [], // array of locations
        person_not_locations: [], // array of locations
        person_location_name: "", // string of zip (if location radius selected)
        person_location_radius: "", // string of radius (if location radius selected)
        in_house_organizations: [],
        in_house_organizations_not: [],
        in_house_organizations_past: [],
        educations: [],
        languages: [],
        seniorities: [],
        skills: [],
        certifications: [],
        followers: "",
        min_num_connection: null,
        min_month_in_current_role: null,
        max_month_in_current_role: null,
      } as any;
      const allFetchedCompanies = _.uniqBy(
        [
          ...fetchedCompanies,
          ...(peopleSearchPayload.fetchedCompanies || []),
          ...(peopleSearchPayload.excludeFetchedCompanies || []),
          ...(peopleSearchPayload.pastFetchedCompanies || []),
        ],
        (company: any) =>
          company?.organization_id ??
          company?.owned_by_organization_id ??
          company?.id,
      );
      if (allFetchedCompanies.length > 0) {
        setFetchedCompanies(allFetchedCompanies);

        newInputData = {
          ...newInputData,
          ...(isUseMixRank
            ? {
                in_house_organizations: (
                  peopleSearchPayload.fetchedCompanies?.slice(0, 1) || []
                ).map((company: any) => ({
                  label: company.company_name,
                  value: company.id,
                })),
                in_house_organizations_not: (
                  peopleSearchPayload.excludeFetchedCompanies?.slice(0, 1) || []
                ).map((company: any) => ({
                  label: company.company_name,
                  value: company.id,
                })),
                in_house_organizations_past: (
                  peopleSearchPayload.pastFetchedCompanies?.slice(0, 1) || []
                ).map((company: any) => ({
                  label: company.company_name,
                  value: company.id,
                })),
              }
            : {
                organization_ids:
                  peopleSearchPayload.fetchedCompanies?.slice(0, 1) || [],
                not_organization_ids:
                  peopleSearchPayload.excludeFetchedCompanies?.slice(0, 1) ||
                  [],
                person_past_organization_ids:
                  peopleSearchPayload.pastFetchedCompanies?.slice(0, 1) || [],
              }),
        };
      }

      Object.entries(peopleSearchPayload).forEach(([key, value]: any) => {
        if (
          [
            "organization_num_employees_ranges",
            "q_organization_keyword_tags",
            "q_not_organization_keyword_tags",
            "skills",
            "certifications",
            "languages",
            "educations",
          ].includes(key)
        ) {
          newInputData[key] =
            value instanceof Array
              ? value?.map((item: any) => ({
                  name: item.replace(",", "-"),
                }))
              : typeof value === "string"
                ? [value]
                : [];
        } else {
          newInputData[key] =
            value instanceof Array
              ? value?.map((item: any) => ({
                  label: item,
                  value: item,
                }))
              : value;
        }
      });

      if (peopleSearchPayload.followers) {
        const followers = peopleSearchPayload.followers.split(":");
        newInputData = {
          ...newInputData,
          followers: followers[1],
          isMoreFollowers: followers[0] === "gt",
          isLessFollowers: followers[0] === "lt",
        };
      }

      const newState = {
        ...newInputData,
      };
      setInputtedData(newState);
      handleSearch(newState);

      window.peopleSearchPayload = null;
      updateLeadState({
        peopleSearchPayload: null,
        appliedPeopleSearchPayload: peopleSearchPayload,
        valueLabels: {
          ...valueLabels,
          ...Object.fromEntries(
            allFetchedCompanies.map((company: any) => [
              company?.organization_id || company?.id || company?.value,
              company?.name || company?.company_name || company?.label,
            ]),
          ),
        },
      });
    } catch (error) {
      console.error(error, "error in sidebar", _peopleSearchPayload);
      toast({
        title: "Error",
        description: "An error occurred",
        status: "error",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
    }
  }, [handleSearch, _peopleSearchPayload]);

  return (
    <div
      className={`flex size-full h-full flex-col justify-between ${
        isForAutoPilot ? "" : "max-h-screen"
      }`}
    >
      <div
        className={`grow space-y-4 bg-white px-2 py-4 ${isForAutoPilot ? "h-full" : "h-[5rem] overflow-y-auto"}`}
      >
        <>
          {currentUser?.role?.toLocaleLowerCase() === "admin" &&
          !isForAutoPilot ? (
            <div className="flex hidden space-x-2">
              <Switch
                isChecked={isUseMixRank}
                colorScheme="purple"
                onChange={() => {
                  updateUserState({ isUseMixrank: !isUseMixRank });
                }}
              />
              <label
                className="font-title text-sm font-semibold"
                htmlFor="people-search-use-mixrank"
              >
                Use MixRank
              </label>
            </div>
          ) : null}
          {/* <FilterComponent className="px-2" /> */}
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<img src={JobTitleIcon} />}>
                Job Titles
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <div id={genId("person_titles")}>
                <p className="sticky mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Job Titles to include
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. CEO, CTO"
                  options={jobTitles}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_titles", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_titles}
                />
              </div>
              <div id={genId("person_not_titles")}>
                <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Job Titles to exclude
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. Intern, Manager"
                  options={jobTitles}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_not_titles", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_not_titles}
                />
              </div>
              <div id={genId("person_past_titles")}>
                <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Past Job Titles to include
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. Intern, Manager"
                  options={jobTitles}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_past_titles", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_past_titles}
                />
              </div>
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<img src={CompanyIcon} />}>
                Company
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              {isUseMixRank ? (
                <>
                  <CompanyNameSearches
                    label="Companies to include"
                    onChange={(value) => {
                      handlePushToArray(
                        { name: "in_house_organizations", value },
                        inputtedData,
                        setInputtedData,
                      );
                      setFetchedCompanies(value);
                    }}
                    value={inputtedData?.in_house_organizations}
                  />
                  <CompanyNameSearches
                    label="Companies to exclude"
                    onChange={(value) => {
                      handlePushToArray(
                        { name: "in_house_organizations_not", value },
                        inputtedData,
                        setInputtedData,
                      );
                      setFetchedCompanies(value);
                    }}
                    value={inputtedData?.in_house_organizations_not}
                  />
                  <CompanyNameSearches
                    label="Past Companies to include"
                    onChange={(value) => {
                      handlePushToArray(
                        { name: "in_house_organizations_past", value },
                        inputtedData,
                        setInputtedData,
                      );
                      setFetchedCompanies(value);
                    }}
                    value={inputtedData?.in_house_organizations_past}
                  />
                </>
              ) : (
                <>
                  <PersanaInput
                    id={genId("organization_ids")}
                    value={inputtedData?.organization_ids}
                    placeholder={"e.g. Google, Facebook, Apple"}
                    name={"organization_ids"}
                    label={"Companies to include"}
                    showDropdown
                    isDynamic
                    arrayOfValues={fetchedCompanies}
                    onChange={(target) => {
                      handleCompanySearch(target.value);
                    }}
                    onClick={(target) => {
                      console.log("target", target);

                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                  <PersanaInput
                    id={genId("not_organization_ids")}
                    value={inputtedData?.not_organization_ids}
                    placeholder={"e.g. Google, Facebook, Apple"}
                    name={"not_organization_ids"}
                    label={"Companies to exclude"}
                    showDropdown
                    isDynamic
                    arrayOfValues={fetchedCompanies}
                    onChange={(target) => {
                      handleCompanySearch(target.value);
                    }}
                    onClick={(target) => {
                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                  <PersanaInput
                    id={genId("person_past_organization_ids")}
                    value={inputtedData?.person_past_organization_ids}
                    placeholder={"e.g. Google, Facebook, Apple"}
                    name={"person_past_organization_ids"}
                    label={"Past Companies to include"}
                    showDropdown
                    arrayOfValues={fetchedCompanies}
                    onChange={(target) => {
                      handleCompanySearch(target.value);
                    }}
                    onClick={(target) => {
                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                </>
              )}
              {!isUseMixRank && (
                <PersanaInput
                  id={genId("organization_num_employees_ranges")}
                  value={inputtedData?.organization_num_employees_ranges}
                  placeholder={"e.g. 11-20 , 101-200"}
                  name={"organization_num_employees_ranges"}
                  label={"Number of Employees"}
                  showDropdown
                  disabledCustomSelect
                  arrayOfValues={apolloCompanySize}
                  onClick={(target) => {
                    handlePushToArray(target, inputtedData, setInputtedData);
                  }}
                />
              )}
              <PersanaInput
                id={genId("q_organization_keyword_tags")}
                value={inputtedData?.q_organization_keyword_tags}
                placeholder={"e.g. Cloud, AWS"}
                name={"q_organization_keyword_tags"}
                label={"Keywords to Include"}
                showDropdown
                arrayOfValues={[]}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
              <PersanaInput
                id={genId("q_not_organization_keyword_tags")}
                value={inputtedData?.q_not_organization_keyword_tags}
                placeholder={"e.g. Cloud, AWS"}
                name={"q_not_organization_keyword_tags"}
                label={"Keywords to exclude"}
                showDropdown
                arrayOfValues={[]}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<img src={LocationIcon} />}>
                Location
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <div id={genId("person_locations")}>
                {isUseMixRank ? (
                  <Countries
                    label="Location to include"
                    value={inputtedData?.person_locations}
                    onChange={(selectedValues) => {
                      handlePushToArray(
                        { name: "person_locations", value: selectedValues },
                        inputtedData,
                        setInputtedData,
                      );
                    }}
                  />
                ) : (
                  <>
                    <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                      City / State / Country or Any Location
                    </p>
                    <MultiSelectDropdown
                      placeholder="e.g. San Francisco, United States"
                      options={extractNameAndContinent(countries)}
                      onChange={(selectedValues) => {
                        handlePushToArray(
                          { name: "person_locations", value: selectedValues },
                          inputtedData,
                          setInputtedData,
                        );
                      }}
                      isCreateAble
                      value={inputtedData?.person_locations}
                    />
                  </>
                )}
              </div>
              <div id={genId("person_not_locations")}>
                {isUseMixRank ? (
                  <Countries
                    label="Location to exclude"
                    value={inputtedData?.person_not_locations}
                    onChange={(selectedValues) => {
                      handlePushToArray(
                        { name: "person_not_locations", value: selectedValues },
                        inputtedData,
                        setInputtedData,
                      );
                    }}
                  />
                ) : (
                  <>
                    <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                      Location to exclude
                    </p>
                    <MultiSelectDropdown
                      placeholder="e.g. San Francisco, United States"
                      options={extractNameAndContinent(countries)}
                      onChange={(selectedValues) => {
                        handlePushToArray(
                          {
                            name: "person_not_locations",
                            value: selectedValues,
                          },
                          inputtedData,
                          setInputtedData,
                        );
                      }}
                      isCreateAble
                      value={inputtedData?.person_not_locations}
                    />
                  </>
                )}
              </div>
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<img src={NameIcon} />}>
                Profile
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <PersanaInput
                id={genId("q_person_name")}
                value={inputtedData?.q_person_name}
                placeholder={"e.g. John Doe"}
                name={"q_person_name"}
                label={"Person Name"}
                onChange={(e) => {
                  setInputtedData({
                    ...inputtedData,
                    q_person_name: e.target.value,
                  });
                }}
              />
              {isUseMixRank ? (
                <>
                  <Educations
                    inputtedData={inputtedData}
                    onClick={(target) => {
                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                  <PersanaInput
                    id={genId("languages")}
                    value={inputtedData?.languages}
                    placeholder={"e.g. English"}
                    name={"languages"}
                    label="Languages"
                    showDropdown
                    arrayOfValues={[]}
                    onClick={(target) => {
                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                  <PersanaInput
                    id={genId("skills")}
                    value={inputtedData?.skills}
                    placeholder={"e.g. Microsoft Office"}
                    name={"skills"}
                    label="skills"
                    showDropdown
                    arrayOfValues={[]}
                    onClick={(target) => {
                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                  <PersanaInput
                    id={genId("certifications")}
                    value={inputtedData?.certifications}
                    placeholder={"e.g. BLSD"}
                    name={"certifications"}
                    label="certifications"
                    showDropdown
                    arrayOfValues={[]}
                    onClick={(target) => {
                      handlePushToArray(target, inputtedData, setInputtedData);
                    }}
                  />
                  <PersanaInput
                    value={inputtedData?.followers}
                    optionComponent={
                      <div className="flex items-center gap-x-3">
                        <RadioButton
                          label={"Greater than"}
                          name={"isMoreFollowers"}
                          checked={inputtedData?.isMoreFollowers}
                          setChecked={(e) => {
                            setInputtedData({
                              ...inputtedData,
                              isMoreFollowers: e.target.checked,
                              isLessFollowers: !e.target.checked,
                            });
                          }}
                        />
                        <RadioButton
                          label={"Less than"}
                          name={"isLessFollowers"}
                          checked={inputtedData?.isLessFollowers}
                          setChecked={(e) => {
                            setInputtedData({
                              ...inputtedData,
                              isLessFollowers: e.target.checked,
                              isMoreFollowers: !e.target.checked,
                            });
                          }}
                        />
                      </div>
                    }
                    placeholder={"e.g. 1000"}
                    name={"followers"}
                    label={"Followers"}
                    type="number"
                    onChange={(e) => {
                      setInputtedData({
                        ...inputtedData,
                        followers: e.target.value,
                      });
                    }}
                  />
                  <PersanaInput
                    id={genId("min_num_connection")}
                    value={inputtedData?.min_num_connection}
                    placeholder={"e.g.100"}
                    name={"min_num_connection"}
                    label="Minimum Number of Connections"
                    showDropdown={false}
                    type="number"
                    onChange={({ target }) => {
                      setInputtedData({
                        ...inputtedData,
                        min_num_connection: target.value,
                      });
                    }}
                  />
                  <PersanaInput
                    id={genId("min_month_in_current_role")}
                    value={inputtedData?.min_month_in_current_role}
                    placeholder={"e.g. 2"}
                    name={"min_month_in_current_role"}
                    label="Minimum Months in Current Role"
                    showDropdown={false}
                    type="number"
                    onChange={({ target }) => {
                      setInputtedData({
                        ...inputtedData,
                        min_month_in_current_role: target.value,
                      });
                    }}
                  />
                  <PersanaInput
                    id={genId("max_month_in_current_role")}
                    value={inputtedData?.max_month_in_current_role}
                    placeholder={"e.g. 24"}
                    name={"max_month_in_current_role"}
                    label="Maximum Months in Current Role"
                    showDropdown={false}
                    type="number"
                    onChange={({ target }) => {
                      console.log("myTarget", target.value);
                      setInputtedData({
                        ...inputtedData,
                        max_month_in_current_role: target.value,
                      });
                    }}
                  />
                  <Seniorities
                    value={inputtedData?.seniorities}
                    onChange={(selectedValues) => {
                      handlePushToArray(
                        { name: "seniorities", value: selectedValues },
                        inputtedData,
                        setInputtedData,
                      );
                    }}
                  />
                </>
              ) : null}
            </div>
          </SearchInputAccordion>

          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<Icons.SearchIcon />}>
                Keyword Search
              </AccordionButton>
            }
          >
            <InputGroup id={genId("q_keywords")} className="rounded-md">
              <InputLeftElement pointerEvents="none">
                <Icons.SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                type="text"
                rounded="md"
                placeholder="Search People..."
                value={inputtedData.search_text}
                onChange={(e) => {
                  handlePushToArray(
                    { name: "search_text", value: e.target.value },
                    inputtedData,
                    setInputtedData,
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(inputtedData);
                  }
                }}
              />
            </InputGroup>
          </SearchInputAccordion>
        </>
      </div>
      {!isForAutoPilot && (
        <ButtonGroup className="!bg-white p-4 !font-title">
          <Button
            isDisabled={totalFilters <= 0}
            isLoading={isSearching}
            id={generateId("persana-search-button")}
            loadingText="Searching"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            className={`!w-full !rounded-[6px] !bg-[#5024DC] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide !text-white`}
            onClick={() => {
              handleSearch(inputtedData);
            }}
          >
            Search
          </Button>
          <IconButton
            colorScheme="gray"
            isDisabled={totalFilters <= 0}
            aria-label="Save Filters"
            icon={<Icons.SaveIcon />}
            onClick={() => {
              saveFilterModalDisclosure.onOpen();
            }}
          />
        </ButtonGroup>
      )}
      <SaveCurrentFilter disclosure={saveFilterModalDisclosure} />
    </div>
  );
};

const Sidebar = ({ isForAutoPilot }: { isForAutoPilot?: boolean }) => {
  return (
    <ControlledTabs size={"sm"} className="size-full bg-white">
      <TabList borderBottom="1px solid #F0EFF1">
        {!isForAutoPilot ? (
          <>
            <Tab>People</Tab>
            <Tab> Company</Tab>
            <Tab>Saved Search</Tab>
          </>
        ) : null}
      </TabList>
      <TabPanels className="size-full">
        <TabPanel className="h-full !p-0">
          <SidebarComponent isForAutoPilot={isForAutoPilot} />
        </TabPanel>
        <TabPanel className="h-full !p-0">
          <CompanySidebarComponent isForAutoPilot={isForAutoPilot} />
        </TabPanel>
        <TabPanel>
          <SavedSearchesUI />
        </TabPanel>
      </TabPanels>
    </ControlledTabs>
  );
};
export default Sidebar;
