import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { Controls, MarkerType, Node, ReactFlow } from "@xyflow/react";
import { useNavigate } from "@tanstack/react-router";
import "@xyflow/react/dist/style.css";

import PersanaTable from "../Common/AutoPilot/PersanaTable/PersanaTable";
import JobOpeningsSourceNode from "./JobOpeningsSourceNode/JobOpeningsSourceNode";

import Icons from "../Icons";
import { createSearchLeadsStore, TabContext } from "../PeopleSearch/store";
import { useTableList } from "@/hooks/useTableList";
import Walkthrough from "./Walkthrough";

const AUTO_PILOT_NODE_ID = {
  JOB_OPENING_SOURCE: "jobOpeningSource",
  JOB_OPENINGS_TABLE: "jobOpeningsTable",
};

const activeColor = "#078A52";

const activeStrokeStyle = {
  strokeWidth: 2,
  stroke: activeColor,
};

const INITIAL_TOOLBAR_STATE = {
  [AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE]: false,
  [AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE]: false,
};

const AutoPilotMultiSourceJobOpeningsWorkflow: React.FC = () => {
  const navigate = useNavigate();
  const { data: allTables, refetch } = useTableList();
  const autoPilotJobOpeningsTable = allTables?.find(
    (item) => item.workflowType === "multi-source-job-openings",
  );
  const isConfigured = !!autoPilotJobOpeningsTable;
  const [needHelp, setNeedHelp] = useState(false);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState([
    {
      id: "e1-2",
      source: AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE,
      target: AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
      },
    },
  ]);
  const [toolbarState, setToolbarState] = useState(INITIAL_TOOLBAR_STATE);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleNodeClick = (e: React.MouseEvent, node: Node) => {
    e.stopPropagation();
    setToolbarState(() => ({
      ...INITIAL_TOOLBAR_STATE,
      [node.id]: true,
    }));
  };

  const handleCanvasClick = () => {
    setToolbarState(INITIAL_TOOLBAR_STATE);
  };

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const nodeWidth = 100;
      const topMargin = 150;

      const startX = (containerWidth - nodeWidth) / 2.5;

      const updatedNodes: Node[] = [
        {
          id: AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE,
          type: "customA",
          position: { x: startX, y: topMargin },
          data: {
            showToolbar: !isConfigured
              ? true
              : toolbarState[AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE],
            isConfigured,
            tableData: autoPilotJobOpeningsTable,
            refetch,
          },
        },
        {
          id: AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE,
          type: "customB",
          position: { x: startX, y: topMargin + 200 },
          data: {
            tableTitle: "Job Openings Table",
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE],
            isDisabled: !isConfigured,
            tableData: autoPilotJobOpeningsTable,
          },
        },
      ];

      setNodes(updatedNodes);

      const updatedEdges = edges.map((edge) => {
        if (
          edge.source === AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE &&
          isConfigured
        ) {
          return {
            ...edge,
            markerEnd: {
              ...edge.markerEnd,
              color: activeColor,
            },
            style: {
              ...activeStrokeStyle,
            },
          };
        }
        return edge;
      });

      setEdges(updatedEdges);
    }
  }, [toolbarState, allTables, isConfigured, autoPilotJobOpeningsTable]);

  return (
    <>
      <div className="flex items-center justify-between bg-white px-[32px] py-[8px]">
        <p className="text-[24px] font-bold leading-[32px]">
          Autopilot - Job Openings with Multiple Sources
        </p>

        <ButtonGroup>
          <Button
            variant={"outline"}
            size={"sm"}
            onClick={() => setNeedHelp(true)}
          >
            Need help ?
          </Button>

          <Button
            size={"sm"}
            colorScheme="primary"
            variant="outline"
            leftIcon={<Icons.BackIcon />}
            className="h-[40px] rounded-md !border-none !bg-light !text-textPurple"
            onClick={() => {
              navigate({ to: "/auto-pilot" });
            }}
          >
            Go Back
          </Button>
        </ButtonGroup>
      </div>
      <div
        ref={containerRef}
        style={{ width: "calc(100vw - 80px)", height: "92vh" }}
        onClick={handleCanvasClick}
      >
        <TabContext.Provider value={createSearchLeadsStore("autopilot_1")}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={{
              customA: JobOpeningsSourceNode,
              customB: PersanaTable,
            }}
            fitView={true}
            onNodeClick={handleNodeClick}
          >
            <Controls />
          </ReactFlow>
        </TabContext.Provider>
      </div>
      <Walkthrough needHelp={needHelp} setNeedHelp={setNeedHelp} />
    </>
  );
};

export default AutoPilotMultiSourceJobOpeningsWorkflow;
