import JobOpeningsTable from "@/assets/images/job-openings-table.png";

const Step2 = () => {
  return (
    <div className="bg-white p-6 rounded-lg border border-gray-300 mx-auto">
      <p className="text-lg font-semibold text-gray-800">
        View & monitor jobs with Persana Job-Openings table
      </p>
      <hr className="border-t-2 border-purple-700 my-4" />

      <div className="text-sm text-gray-700 space-y-2 mt-4">
        <div className="p-4 rounded-lg border border-gray-300 bg-gray-50">
          <img src={JobOpeningsTable} alt="" className="rounded-lg mb-4" />
        </div>
        <p className="text-base font-medium text-gray-800">
          Effortlessly view and track your jobs in the Persana Job-Openings
          table, keeping all your job information organized and up-to-date for
          streamlined management.
        </p>
        <p>
          <span className="font-semibold">1. Select </span> "Job Openings Table"
        </p>
        <p>
          <span className="font-semibold">2. Choose </span> "View Table"
        </p>
      </div>
    </div>
  );
};

export default Step2;
