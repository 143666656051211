import { useEffect, useRef, useState } from "react";
import {
  Controls,
  MarkerType,
  Node,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";

import { useTableList } from "@/hooks/useTableList";
import FilePeopleSourceNode from "./FindPeopleSource/FindPeopleSourceNode";
import SlackNotifications from "../Common/AutoPilot/SlackNotifications/SlackNotifications";
import HubspotStorage from "../Common/AutoPilot/HubspotStorage/HubspotStorage";
import PersanaTable from "../Common/AutoPilot/PersanaTable/PersanaTable";

const AUTO_PILOT_NODE_ID = {
  FIND_PEOPLE_SOURCE: "findPeopleSource",
  PERSANA_TABLE: "persanaTable",
  HUBSPOT_STORAGE: "hubspotStorage",
  SLACK_NOTIFICATIONS: "slackNotifications",
};

const INITIAL_TOOLBAR_STATE = {
  [AUTO_PILOT_NODE_ID.FIND_PEOPLE_SOURCE]: false,
  [AUTO_PILOT_NODE_ID.PERSANA_TABLE]: false,
  [AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE]: false,
  [AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS]: false,
};

const activeColor = "#078A52";

const activeStrokeStyle = {
  strokeWidth: 2,
  stroke: activeColor,
};

const initialEdges = [
  {
    id: "1->3",
    source: AUTO_PILOT_NODE_ID.FIND_PEOPLE_SOURCE,
    target: AUTO_PILOT_NODE_ID.PERSANA_TABLE,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
    },
  },
  {
    id: "2->3",
    source: AUTO_PILOT_NODE_ID.PERSANA_TABLE,
    target: AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
    },
  },
  {
    id: "2->4",
    source: AUTO_PILOT_NODE_ID.PERSANA_TABLE,
    target: AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
    },
  },
];

function WorkFlow() {
  const [toolbarState, setToolbarState] = useState(INITIAL_TOOLBAR_STATE);
  const [nodes, setNodes] = useNodesState<Node>([]);
  const [edges, setEdges] = useEdgesState(initialEdges);

  const containerRef = useRef<HTMLDivElement>(null);

  const { data: allTables, refetch } = useTableList();

  const autoPilotTable = allTables?.find(
    (item) => item.workflowType === "find-people",
  );
  const isConfigured = !!autoPilotTable;

  const handleNodeClick = (e: React.MouseEvent, node: Node) => {
    e.stopPropagation();
    setToolbarState(() => ({
      ...INITIAL_TOOLBAR_STATE,
      [node.id]: true,
    }));
  };

  const handleCanvasClick = () => {
    setToolbarState(INITIAL_TOOLBAR_STATE);
  };

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const nodeWidth = 100;
      const topMargin = 150;

      const startX = (containerWidth - nodeWidth) / 2.5;

      const isHubspotConfigured =
        autoPilotTable?.metaData?.storeOnHubspot !== undefined &&
        autoPilotTable?.metaData?.storeOnHubspot !== null;

      const isSlackConfigured =
        autoPilotTable?.metaData?.sendSlackNotifications != undefined &&
        autoPilotTable?.metaData?.sendSlackNotifications != null;

      const updatedNodes: Node[] = [
        {
          id: AUTO_PILOT_NODE_ID.FIND_PEOPLE_SOURCE,
          type: "customA",
          position: { x: startX, y: topMargin },
          data: {
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.FIND_PEOPLE_SOURCE],
            isConfigured,
            tableData: autoPilotTable,
            refetch,
          },
        },
        {
          id: AUTO_PILOT_NODE_ID.PERSANA_TABLE,
          type: "customB",
          position: { x: startX, y: topMargin + 200 },
          data: {
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.PERSANA_TABLE],
            isDisabled: !isConfigured,
            tableData: autoPilotTable,
          },
        },
        {
          id: AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE,
          type: "customC",
          position: { x: startX - 300, y: topMargin + 450 },
          data: {
            isConfigured: isHubspotConfigured,
            isDisabled: !isConfigured,
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE],
            tableData: autoPilotTable,
            workflowType: "leads-workflow",
            refetch,
          },
        },
        {
          id: AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS,
          type: "customD",
          position: { x: startX + 300, y: topMargin + 450 },
          data: {
            isConfigured: isSlackConfigured,
            isDisabled: !isConfigured,
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS],
            tableData: autoPilotTable,
            refetch,
          },
        },
      ];

      setNodes(updatedNodes);

      const updatedEdges = edges.map((edge) => {
        if (
          edge.source === AUTO_PILOT_NODE_ID.FIND_PEOPLE_SOURCE &&
          isConfigured
        ) {
          return {
            ...edge,
            markerEnd: {
              ...edge.markerEnd,
              color: activeColor,
            },
            style: {
              ...activeStrokeStyle,
            },
          };
        }

        if (
          edge.target === AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE &&
          isHubspotConfigured
        ) {
          return {
            ...edge,
            markerEnd: {
              ...edge.markerEnd,
              color: activeColor,
            },
            style: {
              ...activeStrokeStyle,
            },
          };
        }

        if (
          edge.target === AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS &&
          isSlackConfigured
        ) {
          return {
            ...edge,
            markerEnd: {
              ...edge.markerEnd,
              color: activeColor,
            },
            style: {
              ...activeStrokeStyle,
            },
          };
        }

        return edge;
      });

      setEdges(updatedEdges);
    }
  }, [toolbarState, allTables, isConfigured, autoPilotTable]);

  return (
    <div
      ref={containerRef}
      style={{ width: "calc(100vw - 80px)", height: "88vh" }}
      onClick={handleCanvasClick}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={{
          customA: FilePeopleSourceNode,
          customB: PersanaTable,
          customC: HubspotStorage,
          customD: SlackNotifications,
        }}
        onNodeClick={handleNodeClick}
      >
        <Controls />
      </ReactFlow>
    </div>
  );
}

export default WorkFlow;
