import {
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import PersanaAccordion from "@/components/Common/PersanaAccordion";
import WatchTutorial from "@/components/Common/WatchTutorial";
import ConditionalFormula from "../../Common/ConditionalFormula";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import LinkedInUrlTab from "./LinkedInUrlTab";
import UserDetailsTab from "./UserDetailsTab";

import { ProviderWaterfall } from "@/components/Common/ProviderWaterfall";
import { TProviderOption } from "@/components/Common/ProviderWaterfall/types";
import { useUserConfig } from "@/hooks/useUserConfig";
import { useEnrichStore } from "@/stores/enrich.store";
import { useTableStore } from "@/stores/table.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { Column } from "@/types/enrichment.types";
import { updateInitialColumn } from "@/utils";
import { REGEX, workEmailsTutorialLink } from "../../constants";
import { ZeroBounceCheckbox } from "./components/zerobounce";
import { PROVIDERS, defaultProviders, providerOptions } from "./constants";

type TabName = "Find with LinkedIn URL" | "Use Name & Company";
type FilterType = "fullName" | "first&lastName";

const FindWorkEmail = () => {
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );

  const isWaterfall = selectedEnrichments?.isWaterfall;
  interface EnrichmentMap {
    [key: string]: string;
  }
  const [zeroBounceEnabled, setZeroBounceEnabled] = useState<boolean>(false);
  const [extraColumnEnabled, setExtraColumnEnabled] = useState<boolean>(false);
  const enrichmentMap: EnrichmentMap = {
    findWorkEmailIcypeas: "Icypeas",
    findWorkEmailFindyMail: "FindyMail",
    findWorkEmailDatagma: "Datagma",
    findWorkEmailHunterIo: "HunterIo",
    findWorkEmailLeadMagic: "LeadMagic",
    findWorkEmailProspeoLinkedin: "Prospeo",
    findWorkEmailApollo: "ApolloIo",
    findWorkEmailDropContact: "dropContact",
  };

  const isSingleEnrichmentFromWaterfall = selectedEnrichments?.id
    ? Object.keys(enrichmentMap).includes(selectedEnrichments.id)
    : false;

  const enrichmentLabel = selectedEnrichments?.id
    ? enrichmentMap[selectedEnrichments.id] || "Prospeo"
    : "Prospeo";

  const singleEnrichment =
    PROVIDERS.find((item) => item.value === enrichmentLabel) || PROVIDERS[0];

  const [selectedTab, setSelectedTab] = useState<TabName>(() =>
    isWaterfall ? "Use Name & Company" : "Find with LinkedIn URL",
  );
  const [providers, setProviders] =
    useState<TProviderOption[]>(defaultProviders);
  const [initialUserKeys, setInitialUserKeys] = useState<string[] | null>(null);
  const updateState = useEnrichStore((state) => state.updateState);
  const [selectedNameType, setSelectedNameType] =
    useState<FilterType>("fullName");
  const [selectedFullName, setSelectedFullName] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedCompanyName, setSelectedCompanyName] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedFirstName, setSelectedFirstName] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedLastName, setSelectedLastName] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedLinkedinUrlForWorkEmail, setSelectedLinkedinUrlForWorkEmail] =
    useState<TSelectedColumnOption | null>(null);

  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  console.log("selectedEnrichments", selectedEnrichments);

  const allStates = [
    {
      name: "fullName",
      updateState: setSelectedFullName,
    },
    {
      name: "companyName",
      updateState: setSelectedCompanyName,
    },
    {
      name: "firstName",
      updateState: setSelectedFirstName,
    },
    {
      name: "lastName",
      updateState: setSelectedLastName,
    },
    {
      name: "linkedinUrl",
      updateState: setSelectedLinkedinUrlForWorkEmail,
    },
  ];

  const getColumnIds = (): Column[] => {
    return [
      { name: "linkedinUrl", id: selectedLinkedinUrlForWorkEmail?.keyId },
      { name: "fullName", id: selectedFullName?.keyId },
      { name: "firstName", id: selectedFirstName?.keyId },
      { name: "lastName", id: selectedLastName?.keyId },
      { name: "companyName", id: selectedCompanyName?.keyId },
    ].filter((item) => item.id) as Column[];
  };

  const tabs = [
    {
      name: "Find with LinkedIn URL",
      component: () => (
        <LinkedInUrlTab
          {...{
            selectedLinkedinUrlForWorkEmail,
            setSelectedLinkedinUrlForWorkEmail,
          }}
        />
      ),
      isHide: !!isWaterfall,
    },
    {
      name: "Use Name & Company",
      component: () => (
        <UserDetailsTab
          {...{
            selectedNameType,
            setSelectedNameType,
            selectedFullName,
            setSelectedFullName,
            selectedCompanyName,
            setSelectedCompanyName,
            selectedFirstName,
            setSelectedFirstName,
            selectedLastName,
            setSelectedLastName,
            selectedLinkedinUrlForWorkEmail,
            setSelectedLinkedinUrlForWorkEmail,
          }}
        />
      ),
      isHide: false,
    },
  ].filter((tab) => !tab.isHide) as {
    name: TabName;
    component: () => JSX.Element;
    isHide: boolean;
  }[];

  const getButtonStatus = () => {
    const otherConditions =
      selectedTab === "Find with LinkedIn URL"
        ? !selectedLinkedinUrlForWorkEmail?.keyId
        : selectedNameType === "fullName"
          ? !selectedFullName?.keyId || !selectedCompanyName?.keyId
          : !selectedFirstName?.keyId ||
            !selectedLastName?.keyId ||
            !selectedCompanyName?.keyId;

    if (isWaterfall) {
      return !providers.length || otherConditions;
    }

    return otherConditions;
  };

  const [userConfigQuery, mutateUserConfig] = useUserConfig(
    selectedEnrichments?.enrichmentName ?? null,
  );
  useEffect(() => {
    if (viewMode !== "edit") {
      if (selectedTab === "Find with LinkedIn URL") {
        updateInitialColumn(
          REGEX.LINKEDINPROFILE,
          setSelectedLinkedinUrlForWorkEmail,
        );
      } else {
        if (selectedNameType === "fullName") {
          updateInitialColumn("name", setSelectedFullName);
        } else {
          updateInitialColumn("first name", setSelectedFirstName);
          updateInitialColumn("last name", setSelectedLastName);
        }
        updateInitialColumn("company name", setSelectedCompanyName);
        updateInitialColumn(REGEX.WEBSITE, setSelectedCompanyName);
        updateInitialColumn(
          REGEX.LINKEDINPROFILE,
          setSelectedLinkedinUrlForWorkEmail,
        );
      }
    }
  }, [columns, selectedTab, selectedNameType]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        selectedColumnToEdit?.metaData?.enrichmentMetaData as any;

      if (enrichmentData) {
        setSelectedTab(enrichmentData?.selectedSearchType || "");
        setSelectedNameType(enrichmentData?.selectedNameType || "");
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        if (enrichmentData?.providers?.length) {
          const providers = enrichmentData.providers
            .map((provider: string) =>
              providerOptions.find((p) => p.value === provider),
            )
            .filter(Boolean) as TProviderOption[];
          setProviders(providers);
        }
        if (enrichmentData?.ownKeyProviderList?.length) {
          const ownKeyProviders =
            enrichmentData.ownKeyProviderList.filter((provider: string) =>
              providerOptions.find((p) => p.apikeyParams.keyName === provider),
            ) || [];
          setInitialUserKeys(ownKeyProviders);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        enrichmentData?.columnId?.map((columnId: any) => {
          const relyOnColumn = columns.find(
            (column) => column._id === columnId.id,
          );
          if (relyOnColumn) {
            const state = allStates.find(
              (state) => state.name === columnId.name,
            );
            if (state) {
              state.updateState({
                key: relyOnColumn.name,
                keyId: relyOnColumn._id,
                iconType: relyOnColumn.metaData?.iconType || "url",
              });
            }
          }
        });
      }
      const zeroBounceEnabled = enrichmentData.zeroBounce || false;
      const zeroBounceExtraColumnEnabled =
        enrichmentData.extraColumns?.some?.((column: string) =>
          column?.includes("Zerobounce"),
        ) || false;
      setZeroBounceEnabled(zeroBounceEnabled);
      setExtraColumnEnabled(zeroBounceExtraColumnEnabled);
    }
  }, [selectedColumnToEdit]);

  useEffect(() => {
    if (userConfigQuery.isLoading || !userConfigQuery.data) return;
    if (!selectedEnrichments?.enrichmentName || viewMode === "edit") return;
    const configProviderList: string[] | undefined =
      userConfigQuery.data?.config?.[selectedEnrichments?.enrichmentName]
        ?.selectedProviders;
    if (!configProviderList) return;
    const configProviders = configProviderList.map(
      (provider) => providerOptions.find((p) => p.value === provider)!,
    );

    setProviders(configProviders);
  }, [
    userConfigQuery.data ?? null,
    viewMode,
    selectedEnrichments?.enrichmentName ?? null,
  ]);

  useEffect(() => {
    const isFullNamePreset = columns?.find(
      (item) =>
        item.name.toLocaleLowerCase() === "full name" ||
        item.name.toLocaleLowerCase() === "name",
    );
    isFullNamePreset
      ? setSelectedNameType("fullName")
      : setSelectedNameType("first&lastName");
  }, [columns]);

  return (
    <>
      <div className="overflow-y-auto p-4">
        <WatchTutorial
          subText="Find work email from profile name and company name"
          link={workEmailsTutorialLink}
        />
        {isWaterfall && !isSingleEnrichmentFromWaterfall && (
          <>
            <ProviderWaterfall
              providerOptions={providerOptions}
              selectedProviders={providers}
              setSelectedProviders={setProviders}
              initialUserKeys={initialUserKeys}
              defaultProviders={defaultProviders}
            />
            <Divider my={4} />
            <ZeroBounceCheckbox
              extraColumnEnabled={extraColumnEnabled}
              enableExtraColumn={setExtraColumnEnabled}
              zeroBounceEnabled={zeroBounceEnabled}
              enableZeroBounce={setZeroBounceEnabled}
            />
          </>
        )}

        <Tabs>
          <TabList className="border-b-2 border-gray">
            {tabs.map((tab, index) => (
              <Tab
                fontSize={"base"}
                fontWeight={"bold"}
                key={index}
                onClick={() => {
                  setSelectedTab(tab.name);
                }}
                className="flex-1"
              >
                <span
                  className={
                    tab.name === selectedTab ? "text-blue" : "text-dark"
                  }
                >
                  {tab.name}
                </span>
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabs.map((tab, index) => {
              const Component = tab.component;
              return <TabPanel key={index}>{<Component />}</TabPanel>;
            })}
          </TabPanels>
        </Tabs>

        {selectedTab === "Find with LinkedIn URL" && (
          <PersanaAccordion
            items={[
              {
                button: (
                  <button className="w-full truncate text-left font-semibold text-[#000000]">
                    Increase match rate
                  </button>
                ),

                panel: (
                  <div className="space-y-3">
                    <p className="font-title text-sm font-semibold tracking-wide">
                      For more accurate results, you can also use name and
                      company name to find work email.
                    </p>
                    <UserDetailsTab
                      {...{
                        selectedNameType,
                        setSelectedNameType,
                        selectedFullName,
                        setSelectedFullName,
                        selectedCompanyName,
                        setSelectedCompanyName,
                        selectedFirstName,
                        setSelectedFirstName,
                        selectedLastName,
                        setSelectedLastName,
                        selectedLinkedinUrlForWorkEmail,
                        setSelectedLinkedinUrlForWorkEmail,
                      }}
                    />
                  </div>
                ),
              },
            ]}
          />
        )}
        <ConditionalFormula />
      </div>

      <SaveAndRunEnrichment
        onClear={() => {
          if (!(isWaterfall && !isSingleEnrichmentFromWaterfall)) return;
          mutateUserConfig.mutate({
            selectedProviders: providers.map((provider) => provider.value),
          });
        }}
        isDisabled={getButtonStatus()}
        apiPayload={{
          zeroBounce: zeroBounceEnabled,
          extraColumns:
            zeroBounceEnabled && extraColumnEnabled ? ["Zerobounce"] : [],
          columnId: getColumnIds(),
          selectedSearchType: selectedTab,
          selectedNameType: selectedNameType,
          providers:
            isWaterfall && !isSingleEnrichmentFromWaterfall
              ? providers.map((p) => p.value)
              : isSingleEnrichmentFromWaterfall && isWaterfall
                ? [singleEnrichment?.value]
                : PROVIDERS.slice(0, 1).map((p) => p.value),
          isWaterfall,
        }}
      />
    </>
  );
};

export default FindWorkEmail;
