import { FileRoute } from "@tanstack/react-router";

import { useAutoPilotAccessCheck } from "@/pages/hooks/autoPilot";
import AutoPilotGoogleJobOpeningsWorkflow from "@/components/AutoPilotGoogleJobsWorkflow";

export const Route = new FileRoute(
  "/auto-pilot/google-job-openings-workflow",
).createRoute({
  component: GoogleJobsWorkflowPage,
  errorComponent: () => <div>Error</div>,
});

function GoogleJobsWorkflowPage() {
  useAutoPilotAccessCheck();

  return <AutoPilotGoogleJobOpeningsWorkflow />;
}

export default GoogleJobsWorkflowPage;
