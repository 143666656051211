import { ExternalLinkIcon } from "@chakra-ui/icons";
import { AiOutlineFileSearch } from "react-icons/ai";
import {
  BiBook,
  BiDotsHorizontalRounded,
  BiFolder,
  BiFolderOpen,
  BiPlus,
  BiRightArrowAlt,
  BiSolidDetail,
  BiSortAlt2,
  BiUndo,
} from "react-icons/bi";
import {
  BsArrowLeft,
  BsArrowRight,
  BsBriefcaseFill,
  BsCheck2,
  BsFillPersonVcardFill,
  BsFillSendFill,
  BsFilter,
  BsListColumnsReverse,
  BsMagic,
  BsPersonFillAdd,
  BsPinAngle,
  BsQuestionCircle,
  BsStars,
  BsThreeDots,
  BsUiRadiosGrid,
} from "react-icons/bs";
import { CgScreenShot, CgUserList, CgWebsite } from "react-icons/cg";
import {
  CiCalendar,
  CiCircleChevLeft,
  CiCircleChevRight,
  CiExport,
  CiImageOn,
  CiImport,
  CiLinkedin,
  CiSquarePlus,
  CiViewTable,
} from "react-icons/ci";
import {
  FaCheckSquare,
  FaCoins,
  FaCss3,
  FaFileCsv,
  FaGift,
  FaGithubSquare,
  FaGoogle,
  FaHtml5,
  FaHubspot,
  FaInfoCircle,
  FaKey,
  FaLinkedin,
  FaMailBulk,
  FaMarkdown,
  FaPeopleArrows,
  FaPlus,
  FaQuestion,
  FaRegNewspaper,
  FaSearch,
  FaShoppingBag,
  FaStar,
  FaTable,
  FaTerminal,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import {
  FaArrowLeftLong,
  FaBoltLightning,
  FaBuildingCircleCheck,
  FaCheck,
  FaCircleCheck,
  FaCirclePlay,
  FaDownload,
  FaIndustry,
  FaLightbulb,
  FaLocationDot,
  FaRegCirclePause,
  FaRegCirclePlay,
  FaRegCopy,
  FaRobot,
  FaSalesforce,
  FaSlack,
  FaTwitter,
  FaUserSecret,
  FaWandMagicSparkles,
} from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import {
  FiArrowLeft,
  FiCheckCircle,
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
  FiCopy,
  FiEdit,
  FiHelpCircle,
  FiRefreshCcw,
  FiTrash2,
} from "react-icons/fi";
import { GiGrowth, GiProcessor } from "react-icons/gi";
import {
  HiDotsHorizontal,
  HiOfficeBuilding,
  HiOutlineFilter,
  HiOutlinePhone,
  HiTrendingUp,
  HiX,
} from "react-icons/hi";
import { HiArrowsUpDown, HiOutlineQueueList } from "react-icons/hi2";
import { ImSortAlphaAsc, ImSortAlphaDesc } from "react-icons/im";
import {
  IoIosAdd,
  IoIosArrowDown,
  IoIosArrowDropdown,
  IoIosArrowDropdownCircle,
  IoIosArrowDropleftCircle,
  IoIosArrowForward,
  IoIosArrowRoundDown,
  IoIosArrowRoundUp,
  IoIosArrowUp,
  IoIosCloseCircle,
  IoIosCloseCircleOutline,
  IoIosLink,
  IoIosMailOpen,
  IoIosPeople,
  IoIosRefresh,
  IoIosVideocam,
  IoMdInformationCircleOutline,
  IoMdSave,
} from "react-icons/io";
import {
  IoAddOutline,
  IoArrowBackSharp,
  IoCallSharp,
  IoCheckmark,
  IoChevronDownCircleOutline,
  IoClose,
  IoCopyOutline,
  IoExtensionPuzzleOutline,
  IoPersonCircle,
  IoRadioButtonOnSharp,
  IoSearchSharp,
  IoSettingsOutline,
  IoShareSocial,
  IoSparklesSharp,
  IoWarningSharp,
} from "react-icons/io5";
import { LiaFileImportSolid } from "react-icons/lia";
import { LuEye, LuEyeOff, LuQrCode, LuRefreshCcw } from "react-icons/lu";
import {
  MdAdd,
  MdAlternateEmail,
  MdAssuredWorkload,
  MdAutoFixNormal,
  MdDelete,
  MdDone,
  MdFileUpload,
  MdLocationOn,
  MdMerge,
  MdNumbers,
  MdOutlineAccountTree,
  MdOutlineAdd,
  MdOutlineAdminPanelSettings,
  MdOutlineArrowOutward,
  MdOutlineCleaningServices,
  MdOutlineLayers,
  MdOutlineMailOutline,
  MdOutlineNaturePeople,
  MdOutlinePeopleAlt,
  MdOutlineSearch,
  MdOutlineSelectAll,
  MdOutlineWebhook,
  MdSavedSearch,
  MdSubtitles,
  MdUnfoldMoreDouble,
  MdWebhook,
} from "react-icons/md";
import {
  PiArrowBendUpLeftBold,
  PiArrowBendUpRightBold,
  PiCrown,
  PiFunctionBold,
  PiTrafficSignalBold,
} from "react-icons/pi";
import {
  RiDeleteBin6Line,
  RiDragMove2Fill,
  RiFullscreenFill,
  RiLogoutBoxRLine,
} from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { SiAiohttp, SiGoogleads, SiGooglemybusiness } from "react-icons/si";
import { TbFilterPlus, TbFilterX, TbTemplate } from "react-icons/tb";
import { TfiText } from "react-icons/tfi";
import { TiArrowDownThick, TiFlowSwitch } from "react-icons/ti";

const Icons = {
  SlackIcon: FaSlack,
  CopyIconFa: FaRegCopy,
  ImportIcon: CiImport,
  ExportIcon: CiExport,
  FindPeopleIcon: MdOutlineNaturePeople,
  FindWorkEmailIcon: MdAlternateEmail,
  MoreIcon: BsThreeDots,
  DownloadIcon: FaDownload,
  CopyIcon: IoCopyOutline,
  DeleteIcon: MdDelete,
  BackIcon: IoArrowBackSharp,
  SearchIcon: IoSearchSharp,
  MailIcon: MdOutlineMailOutline,
  QuestionIcon: FaQuestion,
  CircleQuestionIcon: BsQuestionCircle,
  LinkedInIcon: FaLinkedin,
  CampaignIcon: FaMailBulk,
  WebsiteIcon: CgWebsite,
  UserListIcon: CgUserList,
  PeopleIcon: IoIosPeople,
  CompanyDetailsIcon: BiSolidDetail,
  QRCodeIcon: LuQrCode,
  GoogleIcon: FcGoogle,
  GoogleAdsIcon: SiGoogleads,
  CloseIcon: IoClose,
  MdFileUpload,
  PlayIcon: FaRegCirclePlay,
  GithubIcon: FaGithubSquare,
  LinkIcon: IoIosLink,
  WebhookIcon: MdOutlineWebhook,
  ArrowDownIcon: IoIosArrowDown,
  ArrowForwardIcon: IoIosArrowForward,
  CheckCircleIcon: FiCheckCircle,
  AddIcon: IoIosAdd,
  AddIcon2: MdAdd,
  CalendarIcon: CiCalendar,
  ImageIcon: CiImageOn,
  NumbersIcon: MdNumbers,
  TextIcon: TfiText,
  LayersIcon: MdOutlineLayers,
  FunctionIcon: PiFunctionBold,
  LeftArrowIcon: BsArrowLeft,
  LongLeftArrow: FaArrowLeftLong,
  RightArrowIcon: BsArrowRight,
  RightArrowIcon2: BiRightArrowAlt,
  SortAscIcon: PiArrowBendUpLeftBold,
  SortDescIcon: PiArrowBendUpRightBold,
  FilterIcon: HiOutlineFilter,
  FilterAddIcon: TbFilterPlus,
  PinIcon: BsPinAngle,
  FullscreenIcon: RiFullscreenFill,
  ArrowUpIcon: IoIosArrowUp,
  AgentIcon: FaUserSecret,
  ThickArrowDown: TiArrowDownThick,
  SelectAllIcon: MdOutlineSelectAll,
  ArrowLeftIcon: FiArrowLeft,
  DeleteBinIcon: RiDeleteBin6Line,
  PauseIcon: FaRegCirclePause,
  CloseCircleIcon: IoIosCloseCircleOutline,
  HiXIcon: HiX,
  HubspotIcon: FaHubspot,
  DashboardIcon: RxDashboard,
  SettingsIcon: IoSettingsOutline,
  LinkedinIcon: CiLinkedin,
  QuickStartGuideIcon: BiBook,
  CoinsIcon: FaCoins,
  CheckIcon: BsCheck2,
  FillCheckIcon: FaCheck,
  MenuIcon: HiDotsHorizontal,
  CrownIcon: PiCrown,
  PhoneIcon: HiOutlinePhone,
  HelpIcon: FiHelpCircle,
  LogoutIcon: RiLogoutBoxRLine,
  PeopleArrowIcon: FaPeopleArrows,
  WorkloadIcon: MdAssuredWorkload,
  FileImportIcon: LiaFileImportSolid,
  WebhookIconMd: MdWebhook,
  StarsIcon: BsStars,
  StarIcon: FaStar,
  Processor: GiProcessor,
  OutlineAddIcon: MdOutlineAdd,
  GoogleIconFa: FaGoogle,
  MailOpenIcon: IoIosMailOpen,
  ChevronDownIcon: FiChevronDown,
  ChevronUpIcon: FiChevronUp,
  ChevronRightIcon: FiChevronRight,
  ChevronLeftIcon: FiChevronLeft,
  FillCloseCircleIcon: IoIosCloseCircle,
  PersonAddIcon: BsPersonFillAdd,
  LocationIcon: FaLocationDot,
  OutlineSearchIcon: MdOutlineSearch,
  SubtitleIcon: MdSubtitles,
  OfficeBuildingIcon: HiOfficeBuilding,
  SaveIcon: IoMdSave,
  FileSearchIcon: AiOutlineFileSearch,
  FaceIcon: BsBriefcaseFill,
  LocationOnIcon: MdLocationOn,
  IndustryIcon: FaIndustry,
  FileUploadIcon: MdFileUpload,
  ArrowUpDownIcon: HiArrowsUpDown,
  AddOutlineIcon: IoAddOutline,
  ViewTableIcon: CiViewTable,
  SquarePlusIcon: CiSquarePlus,
  PlusIcon: BiPlus,
  CircleArrowIcon: IoIosArrowDropdownCircle,
  LeftCircleArrow: IoIosArrowDropleftCircle,
  CSVIcon: FaFileCsv,
  DownCircleIcon: IoChevronDownCircleOutline,
  QueueListIcon: HiOutlineQueueList,
  CirclePlayIcon: FaCirclePlay,
  RefreshIcon: IoIosRefresh,
  RefreshFailedIcon: FiRefreshCcw,
  ShareIcon: IoShareSocial,
  AccountMonitoringIcon: MdOutlineAccountTree,
  CarouselLeftIcon: CiCircleChevLeft,
  CarouselRightIcon: CiCircleChevRight,
  ExtensionIcon: IoExtensionPuzzleOutline,
  AdminPanelSettingsIcon: MdOutlineAdminPanelSettings,
  TrashIcon: FaTrash,
  DoneIcon: MdDone,
  EditIcon: FiEdit,
  WarningIcon: IoWarningSharp,
  RobotIcon: FaRobot,
  InformationIcon: IoMdInformationCircleOutline,
  ColumnIcon: BsListColumnsReverse,
  OpenEyeIcon: LuEye,
  CloseEyeIcon: LuEyeOff,
  HttpIcon: SiAiohttp,
  DescSortIcon: ImSortAlphaDesc,
  AscSortIcon: ImSortAlphaAsc,
  TeamIcon: MdOutlinePeopleAlt,
  FormalizeIcon: MdAutoFixNormal,
  SwitchIcon: TiFlowSwitch,
  TrendIcon: HiTrendingUp,
  SignalIcon: PiTrafficSignalBold,
  SearchCompanyIcon: MdSavedSearch,
  SearchBarButton: BsFillSendFill,
  BulbIcon: FaLightbulb,
  SortIcon: BiSortAlt2,
  CircleInfoIcon: FaInfoCircle,
  CleanIcon: MdOutlineCleaningServices,
  MoreDoubleIcon: MdUnfoldMoreDouble,
  CallIcon: IoCallSharp,
  TwitterIcon: FaTwitter,
  TemplateIcon: TbTemplate,
  MergeIcon: MdMerge,
  BusinessIcon: SiGooglemybusiness,
  LightningIcon: FaBoltLightning,
  KeyIcon: FaKey,
  PersonDetailsIcon: BsFillPersonVcardFill,
  PersonIcon: IoPersonCircle,
  NewsIcon: FaRegNewspaper,
  ShoppingIcon: FaShoppingBag,
  DragIcon: RiDragMove2Fill,
  VideoIcon: IoIosVideocam,
  GiftIcon: FaGift,
  DropdownIcon: IoIosArrowDropdown,
  ArrowRoundDownIcon: IoIosArrowRoundDown,
  ArrowRoundUpIcon: IoIosArrowRoundUp,
  CheckMarkIcon: IoCheckmark,
  UndoIcon: BiUndo,
  GrowthIcon: GiGrowth,
  Html5Icon: FaHtml5,
  Css3Icon: FaCss3,
  TerminalIcon: FaTerminal,
  MarkdownIcon: FaMarkdown,
  TableIcon: FaTable,
  MagicIcon: FaWandMagicSparkles,
  FolderClosedIcon: BiFolder,
  FolderOpenIcon: BiFolderOpen,
  SalesforceIcon: FaSalesforce,
  RefreshIconDoubleArr: LuRefreshCcw,
  ExternalLinkIcon,
  IoRadioButtonOnSharp,
  BsMagic,
  BsUiRadiosGrid,
  FaPlus,
  FaCheckSquare,
  FaTimes,
  FaCheck,
  CgScreenShot,
  BsFilter,
  TbFilterX,
  FaBuildingCircleCheck,
  FiCopy,
  FiTrash2,
  FaSearch,
  BiDotsHorizontalRounded,
  IoSparklesSharp,
  MdOutlineArrowOutward,
  CircleCheck: FaCircleCheck,
};

export default Icons;
