import { useEffect, useState } from "react";

import { Button, Text, useToast, VStack } from "@chakra-ui/react";
import CustomAccordion from "../Common/CustomAccordion";
import Sidebar from "../PeopleSearch/Sidebar";
import { useSearchLeadsStore } from "../PeopleSearch/store";
import Icons from "../Icons";
import MultiSelectDropdown from "../Common/MultiSelectDropdown";
import { frequencyList } from "./FindPeopleSource/Configurations";
import { useTableList } from "@/hooks/useTableList";
import leadService from "@/services/leads.service";
import { Option } from "react-querybuilder";
import { convertSearchPeoplePayload, filterValidObjKeys } from "@/utils";
import tableService from "@/services/table.service";
import { userStore } from "@/stores/user.store";
import { useQueryClient } from "@tanstack/react-query";

import StorageImg from "@/assets/svgs/auto-pilot-storage.svg";
import AlertImg from "@/assets/svgs/auto-pilot-alerts.svg";
import CustomNodeCard from "../Common/AutoPilot/CustomNodeCard";
import Toolbar from "../Common/AutoPilot/PersanaTable/Toolbar";
import HubspotConfigurations from "../Common/AutoPilot/HubspotStorage/Configurations";
import Configurations from "../Common/AutoPilot/SlackNotifications/Configurations";

interface EngageOption {
  isActive: boolean;
  title: string;
  subtitle: string;
  configStatus: string;
  isConfigured: boolean;
  isDisabled: boolean;
  rightIcon: string;
  imgSrc: string;
  engagementType?: string;
}

function WalkThrough() {
  const [isTriggerLoading, setIsTriggerLoading] = useState(false);
  const [frequencyToUpdate, setFrequencyToUpdate] = useState<Option | null>(
    null,
  );
  const [engageOptions, setEngageOptions] = useState<EngageOption[]>([]);
  const [currentOpenConfig, setCurrentOpenConfig] = useState<string>("");

  const toast = useToast();
  const queryClient = useQueryClient();
  const { data: allTables, isPending, refetch } = useTableList();
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);
  const currentSearchPayload = useSearchLeadsStore(
    (state) => state.sideBarInput,
  );
  const currentUser = userStore((state) => state.currentUser);

  const autoPilotTable = allTables?.find(
    (item) => item.workflowType === "find-people",
  );
  const isConfigured = !!autoPilotTable;
  const maxLeads =
    currentUser?.planType?.toLocaleLowerCase() === "free" ? 25 : 1000;

  const handleTriggerFlow = async () => {
    setIsTriggerLoading(true);
    await leadService.runAutoPilot(
      undefined,
      "update-auto-pilot-table",
      autoPilotTable?._id || "",
    );
    window.open(`/table/${autoPilotTable?._id}`, "_blank");
    setIsTriggerLoading(false);
  };

  const updatePreferences = async () => {
    setIsTriggerLoading(true);
    const convertedPayload = convertSearchPeoplePayload(currentSearchPayload);
    const validInputtedData = filterValidObjKeys(convertedPayload);
    const response = await tableService.updateTableDataV2(
      autoPilotTable?._id || "",
      {
        metaData: {
          ...validInputtedData,
        },
      },
    );
    if (response.success) {
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      handleTriggerFlow();
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    setIsTriggerLoading(false);
  };

  const handleAutoPilot = async () => {
    setIsTriggerLoading(true);
    const convertedPayload = convertSearchPeoplePayload(currentSearchPayload);
    const validInputtedData = filterValidObjKeys(convertedPayload);
    const payload = {
      ...validInputtedData,
      frequencyToUpdate: frequencyToUpdate?.value,
      isForFindEmail: false,
      selectedLeads: [],
      customSelectionNumber: 0,
      isSelectAll: true,
      isForAutoPilot: true,
      totalEntries: maxLeads,
    };
    const response = await leadService.runAutoPilot(
      payload,
      "create-auto-pilot-table",
    );
    queryClient.refetchQueries({
      queryKey: ["tables"],
    });
    window.open(`/table/${response?.data?.tableData?._id}`, "_blank");
    setIsTriggerLoading(false);
  };

  useEffect(() => {
    if (autoPilotTable?.metaData) {
      const freq = frequencyList?.find(
        (item) => item.value === autoPilotTable?.metaData?.frequencyToUpdate,
      ) as Option;

      setFrequencyToUpdate(freq);

      updateLeadState({
        peopleSearchPayload: autoPilotTable?.metaData || null,
      });

      const isHubspotConfigured =
        autoPilotTable?.metaData?.storeOnHubspot !== undefined &&
        autoPilotTable?.metaData?.storeOnHubspot !== null;

      const isSlackConfigured =
        autoPilotTable?.metaData?.sendSlackNotifications != undefined &&
        autoPilotTable?.metaData?.sendSlackNotifications != null;

      setEngageOptions([
        {
          isActive: false,
          title: "Send to Hubspot",
          subtitle: "Storage",
          configStatus: "Hubspot configured",
          isConfigured: isHubspotConfigured,
          isDisabled: !isConfigured,
          rightIcon:
            "https://persana-assets.s3.us-west-1.amazonaws.com/hubspot-logo.png",
          imgSrc: StorageImg,
          engagementType: "hubspot",
        },
        {
          isActive: false,
          title: "Slack Notifications",
          subtitle: "Alerts",
          configStatus: isSlackConfigured
            ? "Configured & Enabled Slack Alerts"
            : "Configured & Disabled Slack Alerts",
          isConfigured: isSlackConfigured,
          isDisabled: !isConfigured,
          rightIcon:
            "https://persana-assets.s3.us-west-1.amazonaws.com/slack.png",
          imgSrc: AlertImg,
          engagementType: "slack",
        },
      ]);
    }
  }, [autoPilotTable]);

  const onCardClick = (engagementType: string) => {
    setCurrentOpenConfig(engagementType);
  };

  const handleClose = (isRefetch: boolean) => {
    isRefetch && refetch();
    setCurrentOpenConfig("");
  };

  return (
    <>
      <div className="w-[calc(90vw_-_80px)] bg-white rounded h-[calc(88vh_-_1rem)] border border-[#7846cc] mt-4 mx-auto shadow p-4 overflow-y-auto space-y-3">
        <CustomAccordion
          title={`Add Find People Source`}
          titleClassName="text-base"
          iconClassName="text-xl"
          avoidRootClassName
          isDefaultOpen={isConfigured ? false : true}
          isCompleted={isConfigured}
          isLoading={isPending}
        >
          <div className="h-full">
            <VStack spacing={4} align="stretch" marginBottom={4}>
              {isConfigured && (
                <Button
                  colorScheme="purple"
                  variant={"outline"}
                  leftIcon={<Icons.RefreshIcon />}
                  size={"sm"}
                  className="w-fit"
                  onClick={handleTriggerFlow}
                  isLoading={isTriggerLoading}
                >
                  Trigger Flow
                </Button>
              )}

              <Text fontSize="md" fontWeight="semibold">
                How frequently you want to fetch the new leads?
              </Text>
              <MultiSelectDropdown
                isMulti={false}
                placeholder="Frequency to update the engagement data"
                closeMenuOnSelect
                options={frequencyList}
                // @ts-expect-error
                value={frequencyToUpdate}
                onChange={(selected) =>
                  setFrequencyToUpdate(selected as Option)
                }
              />
            </VStack>
            <Sidebar isForAutoPilot />
          </div>
          <div>
            <Button
              colorScheme="blue"
              variant="outline"
              size={"sm"}
              isLoading={isTriggerLoading}
              isDisabled={!frequencyToUpdate}
              onClick={() =>
                isConfigured ? updatePreferences() : handleAutoPilot()
              }
            >
              {isConfigured ? "Update" : "Save"}
            </Button>
          </div>
        </CustomAccordion>
        <CustomAccordion
          title="Generate People Table"
          titleClassName="text-base"
          iconClassName="text-xl"
          avoidRootClassName
          isDefaultOpen={isConfigured ? true : false}
          isCompleted={isConfigured}
          isLoading={isPending}
          isDisabled={!isConfigured}
        >
          <div className="pl-6">
            <Toolbar tableId={autoPilotTable?._id || ""} />
          </div>
        </CustomAccordion>
        <CustomAccordion
          title="Engage with Leads"
          titleClassName="text-base"
          iconClassName="text-xl"
          avoidRootClassName
          isDefaultOpen={isConfigured ? true : false}
          isLoading={isPending}
        >
          <div className="grid grid-cols-3 gap-6 pl-6">
            {engageOptions.map((option) => (
              <CustomNodeCard
                className="w-full"
                isActive={false}
                title={option.title}
                subtitle={option.subtitle}
                configStatus={option.configStatus}
                isConfigured={option.isConfigured}
                isDisabled={option.isDisabled}
                rightIcon={option.rightIcon}
                imgSrc={option.imgSrc}
                enableActiveStyleOnHover={true}
                engagementType={option.engagementType}
                onCardClick={onCardClick}
              />
            ))}
          </div>
        </CustomAccordion>
      </div>
      <HubspotConfigurations
        isConfigured={!!autoPilotTable}
        // @ts-expect-error
        tableData={autoPilotTable}
        isOpen={currentOpenConfig === "hubspot"}
        onClose={handleClose}
        workflowType="leads-workflow"
      />

      <Configurations
        isConfigured={!!autoPilotTable}
        // @ts-expect-error
        tableData={autoPilotTable}
        isOpen={currentOpenConfig === "slack"}
        onClose={handleClose}
      />
    </>
  );
}

export default WalkThrough;
